import React from "react";

export const Changelog = ({
  location: { pathname },
  pageContext,
  children,
}) => {
  const { slug, data } = pageContext;
  const dataEntries = Object.entries(data);
  return (
    <>
      <h1>{slug.replace("-", " ")}</h1>
      {dataEntries.map(([version, logArray], i) => {
        return (
          <React.Fragment key={`${version}-${i}`}>
            <div className="flex items-center">
              <h3 className="mr-2">Web Update</h3>{" "}
              <span className="heading-2xl-bold bg-highlight-accent-minimal text-highlight-accent">
                {version}
              </span>
            </div>

            <ul>
              {logArray.map((log, i) => (
                <li key={`${slug}-log-update-${i}`}>{log}</li>
              ))}
            </ul>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Changelog;
